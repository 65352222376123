import { detectClientIp } from '../../workers/ip';
import { useToken } from "../../components/utils/useToken";
import { promotionRequest } from '../../workers/cms';

const GET_CONTENT = 'GET_CONTENT';
const GET_ALL_CMS_CONTENT = 'GET_ALL_CMS_CONTENT';
const DETECT_CLIENT_IP = 'DETECT_CLIENT_IP';
const SHOW_COOKIES_BAR = 'SHOW_COOKIES_BAR';
const CMS_CONTENT_REQUESTING = 'CMS_CONTENT_REQUESTING';
const GET_PROMOTION = 'GET_PROMOTION';
const GET_PROMOTION_ERROR = 'GET_PROMOTION_ERROR';
const CLEAN_PROMOTION = 'CLEAN_PROMOTION';

export const initialState = {
    content: {
        logo: '/images/logo.png',
        favicon: '/images/favicon.png',
        status: '',
        maintenanceWhitelistIps: []
    },
    isContentLoaded: false,
    isLoading: true,
    failRequest: false,
    cmsPages: [],
    clientIp: {},
    promotionsPage: '',
    promotion: '',
    promoTitle: '',
    isShowCookiesBar: {
        status: false,
        height: 0
    },
    frenchLocations: ["FR", "BL", "GP", "MF", "MQ", "NC", "PF", "PM", "RE", "TF", "WF", "GF", "YT"],
};

export default function Cms(state = initialState, action = {}) {
    switch (action.type) {
    case GET_CONTENT:
        return {
            ...state,
            content: {
                ...state.content,
                status: action.payload.data.status,
                maintenanceWhitelistIps: action.payload.data.maintenanceWhitelistIps
            },
            isContentLoaded: true,
            isLoading: false
        };
    case GET_ALL_CMS_CONTENT:
        if (action.payload.authorization) {
            useToken('token', action.payload.authorization.token);
            useToken('refreshToken', action.payload.authorization.refresh_token);
            useToken('expires_in', action.payload.authorization.expires_in);
        }
        return {
            ...state,
            cmsPages: action.payload.data.filter(data => data.slug.includes('footer_') || data.slug === 'personal_data'),
            promotionsPage: action.payload.data.find(data => data.slug === 'promotions').content,
            isLoading: false
        };
    case GET_PROMOTION:
        const promoData = JSON.parse(action.payload.data.content);
        return {
            ...state,
            isLoading: false,
            promotion: promoData.url,
            promoTitle: promoData.title
        };
    case CMS_CONTENT_REQUESTING:
        return {
            ...state,
            isLoading: true
        };
    case GET_PROMOTION_ERROR:
        return {
            ...state,
            isLoading: false,
            promotion: action.payload.errors.detail,
            promoTitle: ''
        };
    case CLEAN_PROMOTION:
        return {
            ...state,
            promotion: '',
            promoTitle: ''
        };
    case DETECT_CLIENT_IP:
        return {
            ...state,
            clientIp: action.payload,
            isClientIpLoaded: true
        };
    case SHOW_COOKIES_BAR:
        return {
            ...state,
            isShowCookiesBar: {
                status: action.status,
                height: action.height,
            }
        };
    default:
        return state
    }
}

export const getClientIp = () => {
    const success = (payload) => ({
        type: DETECT_CLIENT_IP,
        payload
    });
    return dispatch => detectClientIp()
        .then(res => res.text())
        .then(res => {
            let data = res.replace(/[\r\n]+/g, '","').replace(/\=+/g, '":"');
            data = '{"' + data.slice(0, data.lastIndexOf('","')) + '"}';
            const jsonData = JSON.parse(data);
            dispatch(success({
                ip: jsonData.ip,
                loc: jsonData.loc
            }))
        })
        .catch((err) => console.error(`The error occurred on Cloudflare request: ${err}`));
};


export const contentFromCms = (payload) => ({
    type: GET_CONTENT,
    payload
});

export const allCmsContent = (payload) => ({
    type: GET_ALL_CMS_CONTENT,
    payload
});

export const showCookiesBar = (status, height) => ({
    type: SHOW_COOKIES_BAR,
    status,
    height
});

export const getPromotionContent = (lang, slug) => {
    const requesting = () => ({
        type: CMS_CONTENT_REQUESTING
    });
    const success = (payload) => ({
        type: GET_PROMOTION,
        payload
    });
    const fail = (payload) => ({
        type: GET_PROMOTION_ERROR,
        payload
    });
    return dispatch => {
        dispatch(requesting());
        promotionRequest(lang, slug)
            .then(res => dispatch(success(res.data)))
            .catch(err => err.json().then(res => dispatch(fail(res))))
    }
};

export const cleanPromotion = () => ({
    type: CLEAN_PROMOTION
});
