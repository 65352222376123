export const APP_TITLE = 'Scatter Coins Casino';
export const APP_PAGE_DESCRIPTION_EN = `Join the ${APP_TITLE} Casino from PC and mobile and discover bonuses without condition to win on hundreds of games`;
export const APP_PAGE_DESCRIPTION_FR = `Rejoignez le programme ${APP_TITLE} depuis PC et mobile et découvrez les bonus sans conditions pour gagner sur des centaines de jeux et retirer en 24h`;
export const BACK_TO_HOME_EN = 'Back to homepage';
export const BACK_TO_HOME_FR = 'Retour à la page d\'accueil';
export const BANK_TRANSFER_MANUAL = 'bank_transfer_manual';
//ONCE WE RECEIVE THE CORRECT ID WE WILL UPDATE THIS
export const BANK_TRANSFER_MANUAL_ID = 524;
export const GOLD = 'gold';
export const SILVER = 'silver';
export const BRONZE = 'bronze';
export const COIN_MULTIPLIER = 15;
export const WITHDRAWAL_TYPE = 'withdraw_request';
export const DEPOSIT_TYPE = 'deposit';
